.block.location {
    padding-bottom: 50px;
    padding-top: 150px;
    position: relative;

    .title {
        font-size: 26px;
        letter-spacing: 4.95px;
        padding-bottom: 15px;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        &:after {
            background: #000000;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 37px;
        }
    }

    .image-wrapper {
        display: block;
        left: 50%;
        margin-top: 60px;
        position: relative;
        transform: translateX(-50%);
        width: 240.4%;
    }

    span {
        display: none;
    }
}

@media (min-width: 768px) {

    .block.location {
        padding-bottom: 70px;

        .title {
            font-size: 42px;
            letter-spacing: 8px;
            padding-bottom: 10px;
        }

        .image-wrapper {
            margin-top: 100px;
            max-width: 100%;
            position: relative;
            width: 100%;

            span {
                opacity: 0;

                &:after {
                    width: 0;
                }
            }

            &:after {
                background: #000000;
                content: '';
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                transition: all 550ms ease-in-out;
                width: 100%;
            }

            &:hover {

                span {
                    opacity: 1;

                    &:after {
                        width: 69px;
                    }
                }

                &:after {
                    opacity: 0.6;
                }
            }
        }

        span {
            color: #FFFFFF;
            display: block;
            font-size: 26px;
            left: 50%;
            padding-bottom: 7px;
            position: absolute;
            text-transform: uppercase;
            top: 50%;
            transition: all 550ms ease-in-out;
            transform: translate(-50%, -50%);
            z-index: 1;

            &:after {
                background: #EDB51E;
                bottom: 2px;
                content: '';
                display: block;
                height: 2px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                transition: all 550ms ease-in-out;
            }
        }
    }
}