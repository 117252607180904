.block.contact {
    padding: 0 15px;
    padding-top: 150px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
    }

    .title {
        font-size: 26px;
        letter-spacing: 4.95px;
        padding-bottom: 15px;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        &:after {
            background: #000000;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 37px;
        }
    }

    .content {
        padding-top: 30px;
    }

    .item {
        font-size: 20px;
        padding: 30px 0;
        position: relative;

        &:after {
            background: #000000;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &:last-child {
            padding-bottom: unset;

            &:after {
                content: none;
            }
        }
    }

    .icon {
        height: 35px;
        margin: 0 auto;
        width: 35px;

        &.phone {
            background: url('../../imgs/phone.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &.pin {
            background: url('../../imgs/pin.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &.mail {
            background: url('../../imgs/mail.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .name {
        font-family: 'HelveticaNeue-Bold';
        margin-top: 30px;
        text-align: center;
    }

    .text-wrapper {
        margin-top: 20px;
    }

    a {
        color: inherit;
        display: block;
        font-family: 'HelveticaNeue-Light';
        text-align: center;
        text-decoration: none;
    }
}

@media (min-width: 768px) {

    .block.contact {

        .title {
            font-size: 42px;
            letter-spacing: 8px;
            padding-bottom: 10px;
        }

        .holder {
            max-width: 1160px;
        }

        .flex-content {
            display: flex;
            justify-content: space-between;
        }

        .item {
            font-size: 16px;
            padding: 0;
            padding-top: 70px;
            width: 100%;

            &:after {
                content: none;
            }

            &:nth-child(1), &:nth-child(3) {
                max-width: 28%;
            }

            &:nth-child(2) {
                max-width: 44%;
                padding-left: 26px;
                padding-right: 26px;

                &:before {
                    background: #000000;
                    bottom: 5px;
                    content: '';
                    display: block;
                    height: 180px;
                    left: 0;
                    position: absolute;
                    width: 1px;
                }

                &:after {
                    bottom: 5px;
                    content: '';
                    height: 180px;
                    left: unset;
                    right: 0;
                    width: 1px;
                }
            }
        }

        .icon {
            height: 50px;
            width: 50px;
        }

        .name {
            margin-top: 50px;
        }

        .text-wrapper {
            margin-top: 30px;
        }
    }
}

@media (min-width: 1100px) {

    .block.contact {

        .item {
            font-size: 26px;

            &:nth-child(2) {
                max-width: 38%;
            }
        }
    }
}