.block.about {
    background: #000000;
    color: #FFFFFF;
    padding: 100px 15px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
    }

    .title {
        font-size: 26px;
        letter-spacing: 4.95px;
        padding-bottom: 15px;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        &:after {
            background: #FFFFFF;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 37px;
        }
    }

    .text-wrapper {
        margin-top: 60px;
    }

    .text {
        font-family: 'HelveticaNeue-Thin';
        font-size: 20px;
        margin-top: 60px;
        text-align: justify;

        strong {
            font-family: 'HelveticaNeue';
            font-weight: normal;
        }
    }

    .carrousel-wrapper {
        margin: 0 auto;
        max-width: 171px;
        width: 100%;
    }

    .slick-dots {
        bottom: unset;
        margin-top: 40px;
        position: relative;

        li {

            button {
                background: transparent;
                border: 1px solid #EDB51E;
                border-radius: 50%;
                color: transparent;
                height: 10px;
                margin: 0;
                padding: 0;
                transition: all 550ms ease-in-out;
                width: 10px;
            }

            &.slick-active {

                button {
                    background: #EDB51E;
                }
            }
        }
    }
}

@media (min-width: 768px) {

    .block.about {
        padding: 129px 15px;

        .holder {
            max-width: 1330px;
        }

        .flex-content {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .carrousel-wrapper, .text-wrapper {
            margin: unset;
            max-width: 48%;
            width: 100%;
        }

        .text-wrapper {
            margin-top: unset;
        }

        .title {
            font-size: 42px;
            letter-spacing: 8px;
            padding-bottom: 10px;
        }

        .text {
            font-size: 26px;
            margin-top: 54px;
        }

        .item {
            max-width: 220px;
            padding: 0 10px;
            width: 100%;
        }
    }
}