@font-face {
    font-family: 'HelveticaNeue-Bold';
    src: url('../fonts/HelveticaNeue-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue-Light';
    src: url('../fonts/HelveticaNeue-Light.otf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/HelveticaNeue-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue-Thin';
    src: url('../fonts/HelveticaNeue-Thin.otf') format('truetype');
    font-display: swap;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    box-sizing: border-box;
    color: #000000;
    font-family: 'HelveticaNeue';
    letter-spacing: 0;
    margin: 0;

    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;

    &.no-scroll {
        overflow: hidden;
    }
}

.outer-wrapper {
    overflow: hidden;
}

img {
    display: block;
    max-width: 100%;
    width: 100%;
}

p {
    margin: 0;
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.text-inview, .text-waiting {
    opacity: 0;
    transition: all 750ms ease-in-out;
    transform: translateY(50%);

    &.viewed {
        opacity: 1;
        transform: translateY(0);
    }
}

.image-inview, .image-waiting {
    overflow: hidden;

    img {
        opacity: 0;
        transition: transform 2s cubic-bezier(.215,.61,.355,1), opacity 1.2s cubic-bezier(.215,.61,.355,1), border-radius 250ms ease-in-out, max-width 550ms ease-in-out !important;
        transform: skewY(5deg) scale(1.5) translateY(60px);
    }
    
    &.viewed {

        img {
            opacity: 1;
            transform: skewY(0deg) scale(1) translateY(0px);
        }
    }
}

.hidden {
    display: none !important;
}