.navigation {
    background: #FFFFFF;
    left: 0;
    padding: 30px;
    position: fixed;
    top: 0;
    transition: all 550ms ease-in-out;
    width: 100%;
    z-index: 999;

    .logo {
        background: url('../../imgs/wag-logo.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 75px;
        margin: 0 auto;
        opacity: 1;
        position: relative;
        transition: all 550ms ease-in-out;
        width: 120px;
        z-index: 2;
    }

    .list-wrapper {
        background: rgba(255,255,255,0.98);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        transform: translateX(100%);
        transition: all 750ms ease-in-out;
        width: 100%;
        z-index: 1;
    }

    .opt {
        padding-top: 50px;

        &:first-child {
            padding-top: unset;
        }
    }

    .link {
        color: #000000;
        display: block;
        font-family: 'HelveticaNeue-Thin';
        font-size: 28px;
        padding: 0 32px;
        position: relative;
        text-decoration: none;
        width: fit-content;

        &:after {
            background: #EDB51E;
            bottom: -7px;
            content: '';
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            transition: all 550ms ease-in-out;
            transform: translateX(-50%);
            width: 0;
        }

        &.active {
            font-weight: bold;

            &:after {
                width: calc(100% - 64px);
            }
        }
    }

    .toggle-btn {
        background: transparent;
        border: none;
        display: block;
        height: 15px;
        outline: none;
        padding: 0;
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        z-index: 2;
    }

    .bar {
        background: #000;
        height: 1px;
        left: 0;
        position: absolute;
        transition: all 550ms ease-in-out;
        width: 100%;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            opacity: 1;
            top: 50%;
            transform: translateY(-50%);
        }

        &:nth-child(3) {
            bottom: 0;
        }
    }

    &.expanded {

        .list-wrapper {
            transform: translateX(0);
        }

        .bar {
            background: #171C26;

            &:nth-child(1) {
                top: 50%;
                transform: rotate(45deg) translateY(-50%);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                bottom: 50%;
                transform: rotate(-45deg) translateY(50%);
            }
        }
    }

    &.scrollTop {
        padding: 5px;

        .logo {
            height: 60px;
            width: 96px;
        }
    }
}

@media (min-width: 768px) {

    .navigation {
        padding: 66px 70px;

        .logo {
            height: 97px;
            margin: 0;
            width: 150px;
        }

        .list-wrapper {
            background: transparent;
            display: block;
            height: auto;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &:after {
                content: none;
            }
        }

        .list {
            display: flex;
            justify-content: flex-end;
            padding-right: 35px;
        }

        .opt {
            padding-top: unset;
        }

        .link {
            font-size: 14px;
            padding: 0;
            padding-right: 35px;
            text-transform: uppercase;

            &:after {
                left: 0;
                transform: none;
            }

            &:hover, &.active {
                font-weight: bold;

                &:after {
                    width: 50px;
                }
            }
        }

        .toggle-btn {
            display: none;
        }

        &.scrollTop {
            padding: 15px 70px;
    
            .logo {
                height: 80px;
                width: 125px;
            }
        }
    }
}

@media (min-width: 850px) {

    .navigation {

        .logo {
            height: 127px;
            width: 200px;
        }
    }
}