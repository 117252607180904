.block.workshop {
    padding: 0 15px;
    padding-top: 150px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
    }

    .image-wrapper {
        padding: 0 25px;
    }

    .image {
        border-radius: 50%;
        overflow: hidden;
    }

    .title {
        font-size: 26px;
        letter-spacing: 4.95px;
        padding-bottom: 15px;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        &:after {
            background: #000000;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 37px;
        }
    }

    .text-wrapper {
        margin-top: 60px;
    }

    .text {
        font-family: 'HelveticaNeue-Thin';
        font-size: 20px;
        margin-top: 60px;
        text-align: justify;
    }
}

@media (min-width: 768px) {

    .block.workshop {

        .holder {
            margin: unset;
            margin-left: auto;
            max-width: 1335px;
            padding: 0 55px;
            width: 100%;
        }

        .flex-content {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .image-wrapper {
            max-width: 40.8%;
            padding: 0;
        }

        .text-wrapper {
            margin-top: unset;
            max-width: 52.3%;
        }

        .title {
            font-size: 42px;
            letter-spacing: 8px;
            padding-bottom: 10px;
        }

        .text {
            font-size: 26px;
            margin-top: 54px;
        }
    }
}