.block.form {
    padding: 0 15px;
    padding-top: 150px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
    }

    .title {
        font-size: 26px;
        letter-spacing: 4.95px;
        padding-bottom: 15px;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        &:after {
            background: #000000;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 37px;
        }
    }

    .text {
        font-family: 'HelveticaNeue-Thin';
        font-size: 20px;
        margin-top: 60px;
        text-align: center;

        strong {
            font-family: 'HelveticaNeue';
            font-weight: normal;
        }
    }

    .contact-form {
        margin: 0 auto;
        max-width: 640px;
        position: relative;
        width: 100%;
    
        .wrapper-content-form {
            opacity: 1;
    
            &.partial-hide {
                opacity: 0;
            }
        }
    }

    .input-container {
        padding-top: 35px;

        input[type=text], input[type=number],input[type=email], textarea {
            border: 1px solid #B5B4B4;
        }

        &.invalid {

            .input-label {
                color: #CE1C19;
            }

            input[type=text], input[type=number],input[type=email], textarea {
                border: 1px solid #CE1C19;
            }
        }
    }

    .input-label {
        display: block;
        font-family: 'HelveticaNeue-Thin';
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 10px;
        transition: all 550ms ease-in-out;
    }

    input[type=text], input[type=number],input[type=email], textarea {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        border-radius: 0;
        font-family: 'HelveticaNeue';
        font-size: 16px;
        outline: none;
        transition: all 550ms ease-in-out;
        width: 100%;
    }
    
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        margin: 0; 
    }

    .select-wrapper {
        margin: 0 auto;
        max-width: 350px;
        opacity: 1;
        position: relative;
        width: 100%;

        &:after {
            background: url('../../imgs/expand-arrow.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            bottom: 15px;
            content: '';
            display: block;
            height: 9px;
            right: 15px;
            position: absolute;
            width: 17px;
        }

        &.waiting {
            opacity: 0;
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        color: #B5B4B4;
        font-family: 'HelveticaNeue-Light';
        font-size: 14px;
        position: relative;
        text-align: center;
    }

    input[type=text], input[type=number],input[type=email] {
        padding: 11.25px;
    }

    .inputs-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .input-container {
            max-width: 49%;
            width: 100%;
        }
    }

    .radio-title {
        font-family: 'HelveticaNeue-Light';
        font-size: 16px;
        margin-top: 40px;
        text-align: center;
    }

    .radio-label {
        font-family: 'HelveticaNeue-Thin';
        font-size: 16px;
    }

    select {
        border: 1px solid #B5B4B4;
        display: block;
        outline: none;
        padding: 11px 15px 12px;
        transition: all 550ms ease-in-out;
        width: 100%;

        &.invalid {
            border: 1px solid #CE1C19;
        }
    }

    textarea {
        display: block;
        height: 180px;
        margin: 0;
        padding: 15px;
        resize: none;
    }

    input[type=submit] {
        background: #000000;
        border: none;
        color: #FFFFFF;
        display: block;
        font-family: 'HelveticaNeue-Light';
        font-size: 18px;
        height: 50px;
        margin-left: auto;
        margin-top: 20px;
        padding: 0;
        text-transform: uppercase;
        width: 122px;
    }

    input[type=radio] {
        height: 0;
        opacity: 0;
        width: 0;
    }

    .radio-label {
        cursor: pointer;
        padding-left: 35px;
        position: relative;

        &:before {
            border: 1px solid #B5B4B4;
            border-radius: 50%;
            content: '';
            display: block;
            height: 25px;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 25px;
        }

        &:after {
            background: transparent;
            border-radius: 50%;
            content: '';
            height: 15px;
            left: 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all 350ms ease-in-out;
            width: 15px;
        }
    }

    input:checked ~ {

        .radio-label {
            
            &:after {
                background: #EDB51E;
            }
        }
    }
}

@media (min-width: 768px) {

    .block.form {

        .holder {
            max-width: 706px;
        }

        .title {
            font-size: 42px;
            letter-spacing: 8px;
            padding-bottom: 10px;
        }

        .text {
            font-size: 26px;
            margin-top: 54px;
        }

        .flex-content {
            display: flex;
            justify-content: space-between;

            .input-container {
                max-width: 48%;
                width: 100%;
            }
        }

        .inputs-wrapper {

            .input-container {

                &:nth-child(1), &:nth-child(2), &:nth-child(4) {
                    max-width: 20%;
                }

                &:nth-child(3) {
                    max-width: 25%;
                }
            }
        }

        .input-label {
            font-size: 16px;
        }

        .select-wrapper {
            max-width: 420px;
        }

        select {
            cursor: pointer;
            margin: 0 auto;
            max-width: 420px;
        }

        input[type=submit] {
            cursor: pointer;
            transition: cubic-bezier(.4,0,.2,1) 200ms all;

            &:hover {
                background: #EDB51E;
                transform: scale(.95);
            }
        }
    }
} 