.block.brands {
    padding: 0 15px;
    padding-top: 150px;

    .holder {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
    }

    .title {
        font-size: 26px;
        letter-spacing: 4.95px;
        padding-bottom: 15px;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        &:after {
            background: #000000;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 37px;
        }
    }

    .content {
        margin-top: 60px;
    }

    .item {
        display: inline-flex !important;
        justify-content: space-between;
    }

    .flex-content {
        max-width: 47.5%;
        padding-bottom: 20px;
        width: 100%;
    }

    .image-wrapper {
        overflow: hidden;
        padding-bottom: 40px;
        position: relative;
    }

    .image {
        align-items: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    .arrows-container {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 99px;
        width: 100%;
    }

    .slick-prev, .slick-next {
        height: 30px;
        position: relative;
        top: unset;
        transform: unset;
        width: 15px;
        z-index: 99;

        &:before {
            content: none;
        }
    }

    .slick-prev {
        background: url('../../imgs/l-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        left: unset;
    }

    .slick-next {
        background: url('../../imgs/r-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        right: unset;
    }
}

@media (min-width: 768px) {

    .block.brands {
        position: relative;

        .holder {
            max-width: 1177px;
        }

        .text-wrapper {
            margin-top: unset;
        }

        .title {
            font-size: 42px;
            letter-spacing: 8px;
            padding-bottom: 10px;
        }

        .content {
            margin-top: 100px;
        }

        .slick-slider {
            padding: 0 98px;
        }

        .item {
            flex-direction: column;
        }

        .flex-content {
            display: flex;
            justify-content: space-between;
            max-width: 100%;
            padding-bottom: 70px;

            &:last-child {
                padding-bottom: unset;
            }
        }

        .image-wrapper {
            max-width: 26.5%;
            padding-bottom: unset;
            width: 100%;
        }

        .arrows-container {
            display: none;
        }

        .slick-prev, .slick-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .slick-prev {
            left: 0;
        }

        .slick-next {
            right: 0;
        }
    }
} 