.block.cover {
    padding: 154px 0 100px;

    .holder {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
    }

    .container-fluid {
        padding: 0 15px;
    }

    .title {
        font-size: 58px;
        line-height: 68px;
    }

    .desc {
        font-family: 'HelveticaNeue-Thin';
        font-size: 30px;
        line-height: 34px;
        margin-top: 5px;

        strong {
            font-family: 'HelveticaNeue';
            font-weight: normal;
        }
    }

    .see-more {
        align-items: center;
        background: #000000;
        color: #FFFFFF;
        display: flex;
        font-family: 'HelveticaNeue-Light';
        font-size: 14px;
        height: 40px;
        justify-content: center;
        margin-top: 30px;
        text-decoration: none;
        text-transform: uppercase;
        width: 123px;
    }

    .btn-effect {

        &:nth-child(1) {
            display: block;
        }

        &:nth-child(2) {
            display: none;
        }
    }

    .image-wrapper {
        border-radius: 50%;
        margin-top: 82px;
        overflow: hidden;
    }
}

@media (min-width: 768px) {

    .block.cover {
        padding: 286px 0 220px;

        .holder {
            max-width: 1130px;
        }

        .flex-content {
            display: flex;
        }

        .text-wrapper, .image-wrapper {
            max-width: 50%;
            width: 100%;
        }

        .title {
            font-size: 96px;
            line-height: 116px;
            padding-top: 9px;
        }

        .desc {
            font-size: 50px;
            line-height: 62px;
            margin-top: 20px;
        }

        .see-more {
            font-size: 22px;
            height: 60px;
            margin-top: 50px;
            overflow: hidden;
            position: relative;
            transform: scale(1);
            transition: cubic-bezier(.4,0,.2,1) 200ms all;
            width: 198px;

            .btn-effect {

                &:nth-child(1) {
                    transform: translateY(0);
                }

                &:nth-child(2) {
                    transform: translate(-50%, 170%);
                }
            }

            &:hover {
                background: #EDB51E;
                transform: scale(.95);

                .btn-effect {

                    &:nth-child(1) {
                        transform: translateY(-170%);
                    }

                    &:nth-child(2) {
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .btn-effect {
            transition: cubic-bezier(0.23, 1, 0.32, 1) 600ms transform;
    
            &:nth-child(1) {
                display: block;
            }
    
            &:nth-child(2) {
                display: block;
                left: 50%;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
            }
        }

        .image-wrapper {
            margin-top: unset;
        }
    }
}