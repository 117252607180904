.footer {
    background: #EDB51E;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 380px;
        padding: 100px 15px;
        width: 100%;
    }

    .logo {
        max-width: 125px;
        padding-bottom: 10px;
    }

    .item {
        max-width: 256px;
        padding-top: 40px;
        width: 100%;
    }

    .name {
        font-family: 'HelveticaNeue-Bold';
        font-size: 14px;
        text-transform: uppercase;
    }

    .text-wrapper {
        font-family: 'HelveticaNeue-Light';
        font-size: 14px;
        margin-top: 15px;
        text-decoration: none;
    }

    a {
        color: inherit;
        display: block;
        text-decoration: none;
    }

    .links {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        max-width: 92px;
        width: 100%;
    }

    .social {
        max-width: 30px;
        width: 100%;
    }

    .copyright {
        background: #FFFFFF;
        font-family: 'HelveticaNeue-Light';
        font-size: 12px;
        color: #000000;
        padding: 16px 15px 15px;
        text-align: center;

        strong {
            font-family: 'HelveticaNeue';
            font-weight: normal;
        }

        a {
            color: inherit;
            display: block;
            margin-top: 10px;
            text-decoration: none;
        }
    }
}

@media (min-width: 768px) {

    .footer {

        .holder {
            max-width: 1330px;
            padding: 66px 15px 43px;
        }

        .content {
            display: flex;
            justify-content: space-between;
        }

        .logo {
            max-width: 140px;
            min-width: 140px;
            padding-bottom: unset;
        }

        .item {
            padding: 0 5px;
            padding-top: unset;
        }

        .name {
            font-size: 16px;
        }

        .links {
            margin-top: 24px;
            max-width: 110px;
        }

        .social {
            max-width: 35px;
        }

        .flex-child {

            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(3) {
                order: 4;
            }

            &:nth-child(4) {
                order: 3;
            }

            &:nth-child(5) {
                order: 5;
            }
        }

        .text-wrapper {
            font-size: 16px;
            margin-top: 20px;
        }

        .copyright {
            font-size: 13px;
            padding: 15px;
    
            a {
                margin-left: 45px;
                margin-top: unset;
            }
        }

        .copyright-holder {
            display: flex;
            justify-content: flex-end;
            margin: 0 auto;
            max-width: 1330px;
            width: 100%;
        }
    }
}