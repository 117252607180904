.status-message {
    background: transparent;
    border-radius: 2px;
    height: 100%;
    left: 50%;
    max-width: 102%;
    padding: 0 15px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 3;

    .sending, .success, .error {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }

    .icon {
        margin: 0 auto 15px;
        height: 60px;
        width: 60px;

        &.icon-sending {
            background: url('../../imgs/enviando.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &.icon-success {
            background: url('../../imgs/enviado.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .error .text, .success .text, .sending .text {
        color: #000000;
        font-family: 'HelveticaNeue';
        font-size: 26px;
        letter-spacing: 0;
        text-align: center;
        text-transform: uppercase;
    }
}